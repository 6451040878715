import { Outlet, useLocation } from 'react-router-dom';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import styles from './PageLayout.module.scss';
import Loader from 'components/loaders';
import Navbar from 'components/navbar';

function Layout(props) {
	// const freeLinks = ['Reviews', 'Perks & benefits', 'Learning Path'];
	// const paidLinks = ['Course Curriculum', 'Why This', 'FAQs'];
	const dashboardLinks = ['Hybrid Programs', 'Live Programs', 'Submit Query'];
	const premiumLinks = ['Takeaways', 'Learning path', 'FAQs', 'Enroll now'];
	const location = useLocation();
	const { type } = props;

	return (
		<Suspense fallback={<Loader.PageLoader />}>
			{type === 'dynamic' ? (
				<Navbar links={premiumLinks} btnClassName='white' showBanner={location?.pathname?.split('/')[1] === 'paid'} />
			) : type === 'dashboard' ? (
				<Navbar links={dashboardLinks} btnClassName='white' />
			) : type === 'no-back' ? (
				<Navbar />
			) : null}
			<main className={`${styles.main} ${location?.pathname?.split('/')[1] === 'paid' ? styles.paid : ''}`}>
				<Suspense fallback={<Loader.PageLoader />}>
					<Outlet />
				</Suspense>
			</main>
		</Suspense>
	);
}

Layout.propTypes = {
	type: PropTypes.string,
};

export default Layout;
