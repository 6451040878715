import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Button.module.scss';

const Button = props => {
	//  => classname should be added
	const { func, name, className, icon } = props;
	return (
		<button className={`${Styles.Button} ${className}`} onClick={() => func(name)}>
			{name}
			{icon}
		</button>
	);
};

Button.propTypes = {
	func: PropTypes.func,
	icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default Button;
