import { lazy } from 'react';

export default {
	PremiumLanding: lazy(() => import('./landing-premium')),
	PublicLanding: lazy(() => import('./landing-public')),
	HackathonLanding: lazy(() => import('./hackathon')),
	PaidCoursesPage: lazy(() => import('./paid')),
	Auth: lazy(() => import('./auth')),

	Page404: lazy(() => import('./404')),
	ErrorPage: lazy(() => import('./error')),
	Checkout: lazy(() => import('./checkout')),
	PaymentFailed: lazy(() => import('./payment-failed')),
	ForgtoPassword: lazy(() => import('./forgot-password')),
	CoursePurchased: lazy(() => import('./course-purchased')),
	PaymentSuccess: lazy(() => import('./payment-successful')),
	BuyMeCoffeeRedirect: lazy(() => import('./buy-me-coffee')),
};
